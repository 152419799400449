import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'
import {ref} from "vue";

export default () => {
    const{endPointByRole} = useStoreHelper()
    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const leadsCSV_ExportColumns = {
        lead_number: 'Lead number',
        first_name: 'First name',
        last_name: 'Last name',
        email: 'Email',
        phone_no: 'Phone no',
        income: 'Income',
        partner_income: 'Partner Income',
        street: 'Street',
        house_no: 'House number',
        post_code: 'Postcode',
        mortgage_amount: 'Mortgage amount',
        debt: 'Debt',
        created_at: 'Received date',
    };
    let leadsCSV_ExportProfiles = ref([])
    const archiveLead = (AL_id)=>{

        return new Promise((resolve, reject)=>{
            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: t('Archive this lead'), message: t('Are you sure you want to archive this lead?'),
                buttons: [
                    {
                        text: t('No'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-primary' : '',
                        text: t('Yes'),
                        handler: async ()=> {
                            const l = await loadingController.create({message: t('Please wait...')})
                            await l.present()
                            axios.post(endPointByRole+'/lead/archive', {AL_id: AL_id})
                                .then(r=>{
                                    if(r.data.success){
                                        resolve()
                                    }
                                    else{
                                        toastController.create({header: t('Error'), message: r.data.message, position: 'top', duration: 3500, color: 'danger'}).then((t)=>t.present())
                                        reject()
                                    }
                                })
                                .catch(er=>{
                                    let msg = er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error')
                                    toastController.create({header: t('Error'), message: msg, position: 'top', duration: 3500, color: 'danger'}).then((t)=>t.present())
                                    reject()
                                })
                                .then(()=> l.dismiss())
                        }
                    },
                ]
            }).then((a)=> a.present())
        })
    }
    const cancelReturnRequest = async (AL_id, callback) => {
        const iA = await alertController.create({
            cssClass: isDesktop ? 'alert-web' : '',
            header: "Cancel Request", message: "Are you sure you want to cancel this return request?",
            buttons: [
                {
                    text: "No",
                    cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                },
                {
                    cssClass: isDesktop ? 'alert-btn-primary' : '',
                    text: "Confirm",
                    handler: async () => {
                        const l = await loadingController.create({message: "Please wait..."})
                        await l.present()
                        let t = await toastController.create({position:"top", header:"Error!", duration: 3500, color:"danger"})
                        axios.post('/api/advisor/lead/return/cancel', {AL_id: AL_id})
                            .then(r=>{
                                if(r.data.success){
                                    t.color = "tertiary"
                                    t.message = 'Thank you! request canceled.'
                                    t.header = 'Success'
                                }
                                else{
                                    t.message = r.data.message
                                }
                                callback(r.data.success)
                                t.present()
                            })
                            .catch(er=>{
                                callback(false)
                                t.message = er.response ? er.response.status+' '+er.response.statusText : er
                                t.present()
                            })
                            .then(()=>l.dismiss())
                    }
                },
            ]
        })
        await iA.present()
    }
    const setLeadRating = (AL_id, rating)=>{
        return new Promise((resolve, reject)=>{
            axios.post('/api/advisor/lead/set_rating', {AL_id: AL_id, rating: rating})
                .then(r=>{
                    if(r.data.success){
                        resolve(rating)
                    }
                    else{
                        toastController.create({header: t('errors.while_rating_lead'), message: r.data.message, position: 'top', duration: 3500, color: 'danger'}).then((t)=>t.present())
                        reject()
                    }
                })
                .catch(er=>{
                    let msg = er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error')
                    toastController.create({header: t('errors.while_rating_lead'), message: msg, position: 'top', duration: 3500, color: 'danger'}).then((t)=>t.present())
                    reject()
                })
        })
    }
    const getCSVExportProfiles = async (displayLoading)=>{
        let l = await loadingController.create({message: t('Please wait...')})
        if(displayLoading){
            await l.present()
        }
        leadsCSV_ExportProfiles.value.splice(0)
        axios.get('/api/leads/csv_export/csv_export_profiles')
            .then(r=>{
                for(let x in r.data.data){
                    leadsCSV_ExportProfiles.value.push(r.data.data[x])
                }
            })
            .catch(er=>{
                console.error('Error while getting leads csv export profile: '+er.toString());
            })
            .then(()=> l.dismiss())
    }
    return {
        archiveLead,
        cancelReturnRequest,
        getCSVExportProfiles,
        leadsCSV_ExportColumns,
        leadsCSV_ExportProfiles,
        setLeadRating,
    }
}
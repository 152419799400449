import {useStore} from "vuex";

export default () => {
    const store = useStore()
    const updateTracker = ( A_interface, trackers, status ) => {
        if(undefined === status){
            status = true
        }
        for(let x in trackers){
            store.commit('dataChangeTracker/UPDATE_TRACKER', {interface: A_interface, tracker: trackers[x], status: status})
        }
    }

    return {
        updateTracker,
    }
}
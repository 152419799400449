<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Returned Leads')" :description="$t('Total')+' '+pagination.totalRecords+' leads.'"></block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>

                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== '' || mandateFilter !== '' ? 'primary':'')"
                            :title="'Leads '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" lg="8">
                                <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Status') }}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="">{{ $t('All') }}</option>
                                  <option value="1">{{ $t('Return Accepted') }}</option>
                                  <option value="2">{{ $t('Return Rejected') }}</option>
                                  <option value="3">{{ $t('Pending Approval') }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getLeads(true)">{{ $t('Filter') }}</nk-button>
                            <a class="clickable" href="javascript:;" v-on:click="[statusFilter=mandateFilter='',getLeads(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li><!-- li -->
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getLeads(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getLeads(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li><!-- li -->
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  placeholder="Search lead [client name|lead #]"
                  :is-active="searchBarActive"
                  @do-search="searchLeads"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchLeads('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <ion-list v-if="$isHybridPlatform">
                  <template v-for="lead in leads" :key="'ad_rl'+lead.id">
                    <ion-item button
                              v-on:click="lead.return_status === 3 ? leadActionSheet(lead) : $router.push({name: 'LeadDetails', params:{id:lead.AL_id}})"
                              lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                      <ion-avatar slot="start" class="user-avatar">
                        <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                        <span v-else class="text-uppercase">{{ lead.initials }}</span>
                      </ion-avatar>
                      <div>
                        <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                        <ion-text color="medium" class="mt-1 medium d-block">#{{ lead.lead_number }}</ion-text>
                        <ion-text color="medium" class="medium">{{ $t('Returned')+': '+ lead.return_date.date }}</ion-text>
                      </div>
                      <ion-badge slot="end" :color="(lead.return_status === 1 ? 'primary' : (lead.return_status === 2 ? 'danger' : 'medium'))">
                        {{ $t( (lead.return_status === 1 ? 'Accepted' : (lead.return_status === 2 ? 'Rejected' : 'Pending')) ) }}
                      </ion-badge>
                    </ion-item>
                  </template>
                </ion-list>
                <special-table v-else>
                  <table-row is-heading>
                    <table-col break-point="md">
                      <span class="sub-text">Lead#</span>
                    </table-col>
                    <table-col>
                      <span class="sub-text">Lead</span>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Returned On') }}</table-col>
                    <table-col break-point="md">{{ $t('Reason') }}</table-col>
                    <table-col>{{ $t('Status') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="lead in leads" :key="'ld'+lead.id">
                    <table-col break-point="md">{{ lead.lead_number }}</table-col>
                    <table-col>
                      <user-small-card
                          :user-name="lead.first_name+' '+lead.last_name"
                          :image="lead.dp" :initials="lead.initials">
                        <template v-slot:more_info>
                          <span>{{ lead.post_code+' '+lead.city}}{{ (lead.province.length) ? ', '+lead.province:''}}</span>
                          <span class="d-block d-md-none">Lead#: {{lead.lead_number}}</span>
                        </template>
                      </user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <span class="tb-lead">{{ lead.return_date.date }}</span>
                      <span>{{ lead.return_date.time_passed}} ago</span>
                    </table-col>
                    <table-col break-point="md">
                      <span class="lr_reason_trim" v-tooltip="lead.reason">{{ $t(lead.reason) }}</span>
                    </table-col>
                    <table-col>
                      <badge type="primary" dim :content="$t('Accepted')" v-if="lead.return_status === 1"></badge>
                      <badge type="danger" dim :content="$t('Rejected')" v-else-if="lead.return_status === 2"></badge>
                      <badge type="dark" dim :content="$t('Pending')" v-else></badge>
                    </table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" text="View Details" :to="{name: 'LeadDetails', params:{id:lead.AL_id}}"></list-item>
                            <list-item icon="ni-curve-up-left" text="Cancel Request" v-if="lead.return_status === 3" v-on:click="F_cancelReturnRequest(lead.AL_id)"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {
  IonSpinner,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonText,
  IonBadge,
  actionSheetController
} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";
import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import {Badge, DropDown,  } from "@core/components";
import { BlockContent, BlockHead, Column, MainBlock, Row} from "@core/layouts"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import "bootstrap"
import Pagination from "v-pagination-3"

import { useStore } from "vuex"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import useLeadFunctions from "@core/comp-functions/LeadFunctions"
import axios from "@/libs/axios"
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    Badge,
    ListItem,
    DropDownList,
    UserSmallCard,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonSpinner,
    Pagination,
    IonList, IonItem, IonAvatar, IonLabel, IonText, IonBadge,
  },
  setup() {

    const store   = useStore()
    const { cancelReturnRequest } = useLeadFunctions()
    const { updateTracker }     = useDataChangeTracker()
    const {t} = useI18n()
    const {router} = useRouter()
    const leadStatuses = store.state.general_data.leads_statuses

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)
    let leads     = ref([])
    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getLeads(true)
    }

    let cancelToken = false
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/advisor/leads/returned', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('ReturnedLeads.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getLeads(true))

    const searchLeads = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getLeads())

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.advisor.returned_leads, (n) => {
      if(n){
        updateTracker('advisor', ['returned_leads'], false) // reset tracker
        getLeads(true)
      }
    })
    const F_cancelReturnRequest = (AL_id) => {
      cancelReturnRequest(AL_id, (success) => {
        if(success){
          updateTracker('advisor', ['leads_list'])
          for(let x in leads.value){
            if(leads.value[x].AL_id === AL_id){
              leads.value.splice(x, 1)
              break
            }
          }
        }
      })
    }

    const leadActionSheet = async (lead) => {
      let buttons = [
        {
          text: t('View details'),
          handler: ()=>{
            router.push({name: 'LeadDetails', params:{ id: lead.AL_id }})
          }
        },
      ]
      if(lead.return_status === 3){
        buttons.push({
          text: t('Cancel Request'),
          role: 'destructive',
          handler:()=> F_cancelReturnRequest(lead.AL_id)
        })
      }
      const actionSheet = await actionSheetController.create({
        header: t('Choose an action'),
        buttons: buttons,
      });
      await actionSheet.present();
    };

    return {
      F_cancelReturnRequest,
      getLeads,
      leadActionSheet,
      leadStatuses,
      leads,
      loadingData,
      mandateFilter,
      order,
      pagination,
      paginationCallback,
      searchBarActive,
      searchFilter,
      searchLeads,
      statusFilter,
    }
  }
});

</script>

<style scoped>
span.lr_reason_trim {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
</style>
